import browserUtils from '../utils/browserUtils'

const config = {

  getEnvConfig() {
    const domain = browserUtils.getDomain()
    if (typeof domain !== 'string') {
      console.log(`Invalid domain: ${domain}`)
      return false
    }
    switch (domain) {
      case 'dancetonightparty.com':
      case 'www.dancetonightparty.com':
        return this.env.production
      case 'stage1.dancetonightparty.com':
        return this.env.stage
      default:
        return this.env.development
    }
  },

  env: {

    production: {
      frontend: {
        disableConsole: false,
        comingSoon: false,
        offline: false,
        bypassHash: '-1785034061',
      }
    },

    stage: {
      frontend: {
        disableConsole: false,
        comingSoon: false,
        offline: false,
        bypassHash: '-1785034061',
      }
    },

    development: {
      frontend: {
        disableConsole: false,
        comingSoon: false,
        offline: false,
        bypassHash: '-1785034061',
      }
    }

  },

  events: {
    event1: {
      code: 'event-sep1',
      name: 'Best of Y2K',
      description: 'Grab your flip phone, retro basketball jerseys, bucket hats, low rise jeans and let’s get it started in here! This throwback Thursday, we’re taking you to the early 2000’s. Enjoy top hits from the artists who dominated that era. Get your peeps together for a flipping <span className="nowrap">good time!</span>',
      date: 'September 1<sup>st</sup>, 2022',
      time: '9pm-2am',
      imgSrc: '/assets/images/event-1.jpg?v2',
      imgAlt: 'Tall Palm trees stretching into the sky cast shadows on a tiled floor.',
      buttonCTA: 'Reserve my spot',
      eventbriteButtonId: 'eventbrite-widget-modal-trigger-396707150767',
      eventbriteId: '396707150767',
    },
    event2: {
      code: 'event-sep2',
      name: 'It’s Eh Hit!',
      description: 'You bring the beauty, and we’ll bring the beats of popular Canadian artists you know and love. Dress to impress in red and white or go with the classic Canadian tuxedo. Hit the dance floor and get one dance with your fave sk8ter boy or girl. Grab the team you started from the bottom with for an epic night of dancing and socializing. You <span className="nowrap">earned it!</span> ',
      date: 'September 2<sup>nd</sup>, 2022',
      time: '9pm - 2am',
      imgSrc: '/assets/images/event-2.jpg?v2',
      imgAlt: 'A group of people dancing with their hands in the air.',
      buttonID: 'eventbrite-widget-modal-trigger-396707150767',
      buttonCTA: 'Reserve my spot',
      eventbriteButtonId: 'eventbrite-widget-modal-trigger-405028409877',
      eventbriteId: '405028409877',
    }
  }

}

export default config
