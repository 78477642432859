import React from 'react'
import { GoogleMap, Marker, useJsApiLoader } from '@react-google-maps/api'
import './GoogleMap.css';

const mapStyles = {
  width: '100%',
  height: '532px'
};

const mapCentre = {
  lat: 43.6378653,
  lng: -79.4077939
};

const markerPosition = {
  lat: 43.6435899,
  lng: -79.4024709
};

const Map = () => {

  /*----------------------------------------------------
  MAP SETUP
  -----------------------------------------------------*/

  const { isLoaded } = useJsApiLoader({
    id: 'adff06764f3766d6',
    googleMapsApiKey: "AIzaSyBMpjtT_g-LLObyDl9rrydJYIr-YnyUR04"
  })

  const [map, setMap] = React.useState(null)

  const onLoad = React.useCallback(function callback(map) {
    map.setZoom(13)
    setMap(map)
  }, [])

  const onUnmount = React.useCallback(function callback(map) {
    setMap(null)
  }, [])

  /*----------------------------------------------------
  VIEW
  -----------------------------------------------------*/

  return isLoaded ? (
    <div id="map" className="google-map">
      <GoogleMap
        mapContainerStyle={mapStyles}
        center={mapCentre}
        onLoad={onLoad}
        onUnmount={onUnmount}
      >
        <Marker
          position={markerPosition}
          onClick={() => map.setCenter(markerPosition)}
        />
      </GoogleMap>
    </div>
  ) : <></>

}

export default Map
