import React, { useEffect, useRef } from 'react'
import parse from "html-react-parser";
import EventbriteButton from 'react-eventbrite-popup-checkout'

import './Event.css';

const Event = ({ event, divName }) => {

  const intervalCount = useRef(0)

  const classNameGaRegistrationComplete = `${event.code}-registration-complete`

  useEffect(() => {

    // early exit: require event object
    if (typeof event !== 'object' || event === null) {
      console.log('Missing required object: object')
      return
    }

    // early exit: require Eventbrite ID
    if (!event.hasOwnProperty('eventbriteId')) {
      console.log('Missing required object property: event.eventbriteId')
      return
    }

    // early exit: require Eventbrite button ID
    if (!event.hasOwnProperty('eventbriteButtonId')) {
      console.log('Missing required object property: event.eventbriteButtonId')
      return
    }

    // register event
    const eventInterval = setInterval(function () {
      intervalCount.current = intervalCount.current + 1
      if (intervalCount.current > 200) {
        clearInterval(eventInterval)
      }
      const ele = document.getElementById(event.eventbriteButtonId)
      if (typeof ele === 'object' && ele !== null) {
        window.EBWidgets.createWidget({
          widgetType: 'checkout',
          eventId: event.eventbriteId,
          modal: true,
          modalTriggerElementId: event.eventbriteButtonId,
          onOrderComplete: function() {
            console.log('order complete')
          }
        });
        clearInterval(eventInterval)
      }
    }, 40)

  }, [event])

  //  event handler: tracked Google Analytics button click
  const trackRegistrationComplete = (e) => {
    // do nothing
    console.log('trackRegistrationComplete')
    console.log(e)
  }

  // Eventbrite callback: registration complete
  const onEventBriteComplete = () => {
    console.log('onEventBriteComplete')
    const ele = document.querySelector(`.${classNameGaRegistrationComplete}`)
    if (typeof ele !== 'object' || ele === null) {
      console.log('error', `${classNameGaRegistrationComplete} not found`)
      return
    }
    ele.click()
  }

  return (
    <section className={`event ${divName} ${event.code}`}>
      <div className="event__inner">

        <img src={event.imgSrc} alt={event.imgAlt} />

        <div className="event__details text-left text-center-mobile">

          <div className="event__description">
            <p>
              <React.Fragment>{parse(event.description)}</React.Fragment>
            </p>
          </div>

          <div className="event__when text-left text-center-mobile">
            <div className="event__date">
              <label className="label">Date:</label>
              <React.Fragment>{parse(event.date)}</React.Fragment>
            </div>
            <div className="event__separator">|</div>
            <div className="event__time">
              <label className="label">Time:</label>
              <React.Fragment>{parse(event.time)}</React.Fragment>
            </div>
          </div>

          <EventbriteButton
            className={`btn btn-register ${event.code}`} 
            ebEventId={event.eventbriteId} 
            onOrderComplete={onEventBriteComplete}
            isModal={true}
            >
            {event.buttonCTA}
          </EventbriteButton>

          <div className="event__analytics__triggers" aria-hidden="true">
            <button onClick={trackRegistrationComplete} className={classNameGaRegistrationComplete} />
          </div>

        </div>

      </div>
    </section>
  )

}

export default Event
