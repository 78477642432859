
import EventItem from './Event'

import './ListEvents.css';

const ListEvents = ({events}) => {

  return (
    <div className="events">
      <div className="events__row">
        <div className="events__column">
          <EventItem key="event1" divName="event1" event={events.event1} />
        </div>
        <div className="events__column">
          <EventItem key="event2" divName="event2" event={events.event2} />
        </div>
      </div>
    </div>
  )

}

export default ListEvents
