import './Intro.css';

const Intro = () => {

  return (
    <div className="intro">

      <div className="intro__description text-center">
        <p>
          Samsung is hosting two free epic end of summer parties at one of the hottest venues in the city &mdash; <span className="nowrap">Love Child Social House.</span> Flip back in time to the early 2000's and <span className="nowrap">dance the night</span> away to retro hits or vibe to awesome hits from popular <span className="nowrap">world-renowned</span> Canadian artists. <span className="nowrap">Grab your friends,</span> flex your moves, and <span className="nowrap">get ready to</span> <span className="nowrap">experience FlexCam!</span>
        </p>
      </div>

      <div className="intro__details text-center text-center-mobile">
        <p className="intro__details__when">
          Time: 9pm&ndash;2am
        </p>
        <p className="intro__details__where">
          Place: Love Child Social House
        </p>
      </div>

    </div>
  )

}

export default Intro
