import './Header.css';

import heroDesktop from '../assets/images/header-desktop.jpg'
import heroMobile from '../assets/images/header-mobile.jpg'

const Header = () => {

  return (
    <header className="header">
      <div className="header__hero">

        <img className="header__hero__mobile show-mobile hide-tablet hide-desktop" alt="Samsung Galaxy Z Flip4 is open to show screen. On the phone screen there’s a group of people dancing with their hands in the air. Samsung Galaxy Z Flip4 logo." src={heroMobile} />

        <img className="header__hero__desktop hide-mobile show-tablet show-desktop" alt="Samsung Galaxy Z Flip4 is open to show screen. On the phone screen there’s a group of people dancing with their hands in the air. Samsung Galaxy Z Flip4 logo." src={heroDesktop} />

      </div>
    </header>
  )

}

export default Header
