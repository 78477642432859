import { useRef } from 'react'
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'

import browserUtils from './utils/browserUtils'
import config from './config/index'
import securityUtils from './utils/securityUtils'

import ComingSoon from './pages/ComingSoon'
import Main from './pages/Main'
import Offline from './pages/Offline'

// config
const envConfig = config.getEnvConfig()

// setup dependencies
const querystring = new URLSearchParams(window.location.search)
const querystringBypass = querystring.get('bypass')
const querystringBypassHash = querystringBypass !== null ? securityUtils.getHash(querystring.get('bypass')).toString() : ''

function getShowOffline() {
  if (envConfig.frontend.offline && querystringBypassHash !== envConfig.frontend.bypassHash) return true
  return false
}

function getShowComingSoon() {
  if (envConfig.frontend.comingSoon && querystringBypassHash !== envConfig.frontend.bypassHash) return true
  return false
}

function App() {

  /*
  -----------------------------------------------------------------
  setup
  -----------------------------------------------------------------
  */

  // useRef
  const showOffline = useRef(getShowOffline())
  const showComingSoon = useRef(getShowComingSoon())

  // runtime setup
  if (envConfig.frontend.disableConsole) browserUtils.disableConsole()

  /*
  -----------------------------------------------------------------
  views
  -----------------------------------------------------------------
  */

  // show: offline (eg, maintenance mode)
  if (showOffline.current) {
    return (
      <>
        <Router>
          <Routes>
            <Route path='*' element={<Offline />} />
          </Routes>
        </Router>
      </>
    )
  }

  // show: coming soon
  if (showComingSoon.current) {
    return (
      <>
        <Router>
          <Routes>
            <Route path='*' element={<ComingSoon />} />
          </Routes>
        </Router>
      </>
    )
  }

  // live UX
  return (
    <>
      <Router>
        <Routes>
          <Route path='*' element={<Main />} />
        </Routes>
      </Router>
    </>
  )

}

export default App
