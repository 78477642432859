import config from '../config/index'

import Header from '../components/Header'
import Intro from '../components/Intro'
import ListEvents from '../components/Events/ListEvents'
// import Footer from '../components/Footer'
import GoogleMap from '../components/GoogleMap'

import './Main.css'

const Main = () => {

  return (
    <>
      <Header />
      <main className="main">

        <div className="main__inner">

          <Intro />
          <ListEvents events={config.events} />
          <GoogleMap />

        </div>

      </main>
      {/* <Footer /> */}
    </>
  )

}

export default Main
